<template>
  <div>
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-cellphone-cog</v-icon>
      الاعدادات
      <back-button />
    </h2>
    <hr class="my-5 header-divider" />

    <v-tabs centered dark background-color="secondary" large height="50">
      <v-tab active-class="secondary darken-2" to="/admin/pages/terms">
        <v-icon class="ml-3">mdi-lock</v-icon> الشروط و الاحكام
      </v-tab>
      <v-tab active-class="secondary darken-2" to="/admin/pages/privacy">
        <v-icon class="ml-3">mdi-briefcase-account</v-icon>سياسة الاستخدام
      </v-tab>
      <v-tab active-class="secondary darken-2" to="/admin/pages/about">
        <v-icon class="ml-3">mdi-cellphone</v-icon>عن التطبيق
      </v-tab>
      <v-tab active-class="secondary darken-2" to="/admin/pages/support">
        <v-icon class="ml-3">mdi mdi-book-edit-outline</v-icon>الدعم
      </v-tab>
      <v-tab active-class="secondary darken-2" to="/admin/pages/faq">
        <v-icon class="ml-3">mdi mdi-chat-question-outline</v-icon>الاسالة الشائعة
      </v-tab>
      <v-tab active-class="secondary darken-2" to="/admin/pages/contact">
        <v-icon class="ml-3">mdi mdi-cellphone-basic</v-icon>توصل معنا
      </v-tab>
      <v-tab active-class="secondary darken-2" to="/admin/pages/versions">
        <v-icon class="ml-3">mdi mdi-cellphone-basic</v-icon>إصدار التطبيقات
      </v-tab>
    </v-tabs>

    <v-card>
      <!-- CONTENT -->
      <v-card-text class="pt-5 pb-0">
        <v-container>
          <v-progress-linear
            v-if="contentLoading"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <div v-else>
            <div class="text-center">
              <v-btn-toggle dense rounded class="mb-6" v-model="language">
                <v-btn v-if="pageSlug!='contact' && pageSlug!='versions'" value="ar">Arabic</v-btn>
                <v-btn v-if="pageSlug!='contact' && pageSlug!='versions'" value="en">English</v-btn>

              </v-btn-toggle>


              <div v-if="pageSlug == 'contact'">
              <p>رابط الفيس بوك</p>
                <v-text-field
                  solo
                  label="facebook"
                  hide-details="auto"
                  name="any"
                  v-model="facebook"
                  class="mb-3 ltr"
                  left
                ></v-text-field>
              <p>رابط الانستاجرام</p>

                <v-text-field
                  solo
                  label="instagram"
                  hide-details="auto"
                  name="any"
                  v-model="instagram"
                  class="mb-3 ltr"
                ></v-text-field>
                <p>رقم الهاتف</p>
                <v-text-field
                  solo
                  label="phone"
                  hide-details="auto"
                  name="any"
                  v-model="phone"
                  class="mb-3 ltr"
                ></v-text-field>
                <p>رابط التيك يوك</p>
                <v-text-field
                  solo
                  label="tiktok"
                  hide-details="auto"
                  name="any"
                  v-model="tiktok"
                  class="mb-3 ltr"
                ></v-text-field>
                <p>رابط تويتر</p>
                <v-text-field
                  solo
                  label="twitter"
                  hide-details="auto"
                  name="any"
                  v-model="twitter"
                  class="mb-3 ltr"
                ></v-text-field>
              </div>


              <div v-if="pageSlug == 'versions'">
              <p>إصدار Android</p>
                <v-text-field
                  solo
                  label="android"
                  hide-details="auto"
                  name="any"
                  v-model="android"
                  class="mb-3 ltr"
                  left
                ></v-text-field>
                <p>إصدار IOS</p>

                <v-text-field
                  solo
                  label="ios"
                  hide-details="auto"
                  name="any"
                  v-model="ios"
                  class="mb-3 ltr"
                ></v-text-field>

              </div>
            </div>





            <p v-if="hotline != ''">الرقم الساخن</p>
            <v-text-field
              v-if="hotline != ''"
              solo
              label="HOTLINE"
              hide-details="auto"
              name="any"
              v-model="hotline"
              class="mb-3"
            ></v-text-field>



            <div v-if="pageSlug != 'contact' && pageSlug!='versions'">
              <wysiwyg v-if="language" rows="9" outlined v-model="content" />
            </div>



          </div>
        </v-container>
      </v-card-text>

      <!-- ACTIONS -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" elevation="1" dark @click="update(content)"
          >حفظ</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>


<script>
export default {
  name: "pages",

  data: function () {
    return {
      content: "",
      contact: "",
      versions: "",
      hotline: "",
      facebook: "",
      twitter: "",
      instagram: "",
      android: "",
      ios: "",
      phone: "",
      tiktok: "",
    //  social: ["facebook", "twitter", "instagram", "phone", "tiktok"],
      pageSlug: 'contact',
      language: null,
      contentLoading: false,

      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ direction: "rtl" }],

            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
created(){
this.fetchContent()
},
  watch: {
    "$route.params.page": {
      handler(pageSlug) {
        this.pageSlug = pageSlug;
        this.language = null;
      },
      immediate: true,
    },

    language: {
      handler(language) {
        if (!language) return;
        this.fetchContent();
      },
      immediate: true,
    },
  },

  methods: {
    fetchContent(lang) {
      this.contentLoading = true;

      this.axios
        .get(`/admin/settings/${this.pageSlug}?showNames/`, {
          headers:  { language: this.language }  ,
        })
        .then((response) => {
          this.pageSlug == "support"
            ? ((this.content = response.data.content.note),
              (this.hotline = response.data.content.hotline))
            : this.pageSlug == "contact"
            ? ((this.facebook = response.data.content.facebook),
              (this.twitter = response.data.content.twitter),
              (this.tiktok = response.data.content.tiktok),
              (this.phone = response.data.content.phone),
              (this.instagram = response.data.content.instagram))
            : (this.pageSlug == "versions"?(
                    (this.android = response.data.content.android),
                      (this.ios = response.data.content.ios)
                  ):
                      (this.content = response.data.content));
        })
        .catch((error) => {
          // // console.log(error, error.response);
          this.$bus.$emit("showSnackbar", {
            text: "There was a mistake when rendering the page.",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.contentLoading = false;
        });
    },

    update() {
      this.contentLoading = true;
      (this.pageSlug == "contact" ||this.pageSlug == "versions")  ? this.language = 'en' : this.language;
      this.axios
        .post(
          `/admin/settings/${this.pageSlug}?lang=${this.language}`,
          this.pageSlug == "support"
            ? {
                content: {
                  hotline: this.hotline,
                  note: this.content,
                },
              }
            : this.pageSlug == "contact"
            ? {
                content: {
                  facebook: this.facebook,
                  twitter: this.twitter,
                  phone: this.phone,
                  tiktok: this.tiktok,
                  instagram: this.instagram,
                },
              }
            : this.pageSlug == "versions"
                ? {
                  content: {
                    android: this.android,
                    ios: this.ios
                  },
                }
            : { content: this.content }
        )
        .then((response) => {
          // console.log(response);
          this.$bus.$emit("showSnackbar", {
            text: "The page updated successfully.",
            color: "success",
          });
          // // console.log();
        })
        .catch((error) => {
          this.$emit("failure");
          this.$bus.$emit("showSnackbar", {
            text: "While updating the page, an error occurred.",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.contentLoading = false;
        });
    },
  },
};
</script>
<style scoped>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
.txt-en {
  direction: ltr;
}
.ltr{
  direction: ltr;
}
</style>